<template lang="pug">
    .row
        .col-xs-12(style="margin-bottom:15px" v-show="!loading.main")
            OptiButton(v-show="!page_catalog" @click.native="$emit('go-to-page', 'summary')") {{'tasks.pack.suggest.action.BACK'|translate}}
                template(#icon)
                    i.fa.fa-arrow-left

            OptiButton(v-show="page_catalog" @click.native="resetChanges") {{'catalog.lenses.FIRSTCHOICE'|translate}}
                template(#icon)
                    i.fa.fa-refresh

            OptiButton.pull-right(v-show="type_id && !page_catalog" @click.native="goToPageCatalog"
                :disabled="!(type_id && types && types[type_id].image_url)") {{'tasks.lenses.page.CATALOG'|translate}}
                template(#icon)
                    i.fa.fa-file

        .col-xs-12(v-if="type_id && page_catalog")
            h4.text-center.page-header
                a.link(:href="$state.href('app.catalog.category', {id: types[type_id].category_id, isOffer: isOffer ? 1 : 0, taskId: task.id})"
                    target="_blank" :title="'default.REFERENCETYPE'|translate") {{types[type_id].name_long}}
        .col-xs-12(v-if="!page_catalog && !loading.main")
            .row
                .col-md-4.col-sm-6.col-xs-12
                    h4.text-center.page-header(style="margin-top:0") {{'catalog.lenses.properties.DESIGN'|translate}}

                    select.form-control(style="margin-bottom:15px" :class="{'placeholder': catalog.sub_id === null}"
                        v-model="catalog.sub_id" @change="updateCatalogSub")
                        option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                        optgroup(v-for="(lens, lens_type) in lenses" :key="lens_type" :label="lens_type" v-if="lens_type !== 'Szajna'")
                            option(v-for="(l, type) in lens" :value="type") {{type}}
                        option(v-for="(l, type) in lenses['Szajna']" :value="type" v-if="lenses['Szajna']") {{type}}

                    select.form-control(style="margin-bottom:10px" size="10" :class="{'placeholder': design === null}"
                        v-model="design" @change="updateDesign")
                        option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                        option(v-for="design in lenses[catalog.id][catalog.sub_id]" :key="design.id" :value="design") {{design.name}}

                    OptiTaskDetailsLensesRanges(v-if="!loading.range"
                        :ranges="ranges")

                    div(style="position:relative;margin-top:25px" v-else)
                        OptiDimmer(:active="true")

                .col-md-8.col-sm-6.col-xs-12(v-if="design")
                    h4.text-center.page-header(v-if="type_id" :class="{'pictograms': types[type_id].pictograms.construction_and_index.length}")
                        a.link(:href="$state.href('app.catalog.category', {id: types[type_id].category_id, isOffer: isOffer ? 1 : 0, taskId: task.id})"
                            target="_blank" :title="'default.REFERENCETYPE'|translate") {{types[type_id].name_long}}
                        OptiPictograms(style="white-space:nowrap" v-show="types[type_id].pictograms.construction_and_index.length"
                            :pack-details="types[type_id].pictograms.construction_and_index")
                    h4.text-center.page-header(v-else) {{'catalog.lenses.properties.CONSTRUCTION'|translate}}

                    .form-horizontal
                        .form-group
                            label.col-md-3.control-label(for="lens_selection") {{'catalog.lenses.partition.KIND'|translate}}
                            .col-md-6
                                select#lens_selection.form-control(:class="{'placeholder': design_type_id === null}"
                                    :disabled="Object.keys(design.data).length === 1"
                                    v-model="design_type_id" @change="updateDesignType(design_type_id)")
                                    option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                                    option(v-for="(design_type, design_type_name) in design.data" :value="design_type_name") {{design_type_name}}

                            .col-md-3.text-center(v-if="type_id")
                                small.text-muted.page-header {{types[type_id].brutto_price|currency}}

                        .form-group(v-if="design_type_id && Object.keys(design.data[design_type_id]).length > 1")
                            label.col-md-3.control-label(for="clear_polar_photo") {{'catalog.lenses.partition.PHOTO/POLAR'|translate}}
                            .col-md-6
                                select#clear_polar_photo.form-control(:class="{'placeholder': clear_polar_photo_id === null}"
                                    v-model="clear_polar_photo_id" @change="updatePolSen(clear_polar_photo_id)")
                                    option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                                    option(v-for="clear_polar_photo_name in sortedOptionsClearPolarPhoto"
                                        :key="clear_polar_photo_name"
                                        :value="clear_polar_photo_name") {{ 'tasks.pack.catalog.polsen.' + clear_polar_photo_name | translate }}

                        .form-group(v-if="clear_polar_photo_id")
                            label.col-md-3.control-label(for="index") Index
                            .col-md-6
                                select#index.form-control(:class="{'placeholder': index_id === null}"
                                    :disabled="Object.keys(design.data[design_type_id][clear_polar_photo_id]).length === 1"
                                    v-model="index_id" @change="updateIndex(index_id, true)")
                                    option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                                    option(v-for="(index, index_name) in design.data[design_type_id][clear_polar_photo_id]" :value="index_name") {{index_name}}

                        .form-group(v-if="types && Object.keys(types).length > 1 && !mode_undefined")
                            label.col-md-3.control-label(for="type_tech") {{'catalog.lenses.partition.' + (Object.keys(types)[0] === 'Konwencjonalna' ? 'TECH' : 'SORT')|translate}}
                            .col-md-6
                                select#type_tech.form-control(:class="{'placeholder': type_id === null}"
                                    v-model="type_id" @change="updateType(type_id)")
                                    option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                                    option(v-for="(type, type_name) in types" :value="type_name") {{type_name}}

                        .form-group(v-if="options && options[2][field.progression_length].length")
                            label.col-md-3.control-label(for="progression_length") {{'catalog.lenses.properties.heading.progression_length'|translate}}
                            .col-md-6
                                select#progression_length.form-control(:class="{'placeholder': selected[field.progression_length] === null}"
                                    v-model="selected[field.progression_length]" @change="updateOption(field.progression_length)")
                                    option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                                    option(v-for="radio in options[2][field.progression_length]" :value="radio.id"
                                        :selected="radio.id === selected[field.progression_length]") {{radio.name_long}}

                        .form-group(v-if="options && options[2][field.degression_length].length")
                          label.col-md-3.control-label(for="degression_length") {{'catalog.lenses.properties.heading.' + field.degression_length|translate}}
                          .col-md-6
                            select#degression_length.form-control(:class="{'placeholder': selected[field.degression_length] === null}"
                              v-model="selected[field.degression_length]" @change="updateOption(field.degression_length)")
                              option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                              option(v-for="radio in options[2][field.degression_length]" :value="radio.id"
                                :selected="radio.id === selected[field.degression_length]") {{radio.name_long}}

                    div(v-if="index_id || mode_undefined")
                        h4.text-center.page-header(:class="{'pictograms': pictogramsImprovements.length}")
                            span(v-if="!pictogramsImprovements.length") {{'catalog.lenses.properties.HEADING'|translate}}
                            OptiPictograms(v-else :pack-details="pictogramsImprovements")

                        .form-horizontal
                            .div(v-for="(option, option_index) in options"
                                v-if="isNotEmptyOption(option) || (option_index === 2 && selected_option.coloration && selected_option.coloration.options)")
                                .form-group(v-if="option_index === 2 && selected_option.coloration && selected_option.coloration.options")
                                    label.col-md-3.control-label(for="saturation") {{'catalog.lenses.properties.heading.saturation'|translate}}
                                    .col-md-6
                                        select#saturation.form-control(:class="{'placeholder': selected.coloration < 0}"
                                            :disabled="selected_option.coloration.options.length === 1"
                                            v-model="selected.coloration" @change="updateOption('coloration', true)")
                                            option(v-for="opt in selected_option.coloration.options" :key="opt.id" :class="{'hide': opt.id < 0}"
                                                :disabled="disabledSaturation(opt)" :value="opt.id") {{opt.name}}

                                .form-group(v-for="(section, section_name) in option"
                                    v-if="isNotEmptySection(section) && section_name !== field.progression_length && section_name !== field.degression_length && section_name !== field.degression_length  && section_name !== 'gradient_type' && notHideSzajnaMirrorChromax(section_name)")
                                    label.col-md-3.control-label(:for="section_name") {{'catalog.lenses.properties.heading.' + section_name|translate}}
                                    .col-md-6(v-if="isNaN(Object.keys(section)[0])")
                                        select.form-control(:id="section_name" :class="{'placeholder': selected[section_name] === null}"
                                            :disabled="isDisabledOption(section)"
                                            v-model="selected[section_name]" @change="updateOption(section_name)")
                                            option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                                            option(v-if="Object.keys(section)[0] === section_name && section[section_name].length"
                                                v-for="option in section[section_name]" :value="option.id") {{option.name_long}}
                                            option(v-if="section['szajna_coatings'] && section['szajna_coatings'].length"
                                                v-for="option in section['szajna_coatings']" :value="option.id") {{option.name_long}}
                                            optgroup(v-for="(sub_options, sub_section_name) in section"
                                                v-if="section_name !== sub_section_name && sub_options.length && sub_section_name !== 'szajna_coatings'"
                                                :key="sub_section_name" :label="'catalog.lenses.properties.heading.' + sub_section_name|translate")
                                                option(v-for="sub_option in sub_options" :value="sub_option.id"
                                                    :disabled="disabledColor(sub_section_name, sub_option)") {{sub_option.name_long}}

                                    .col-md-6(v-else)
                                        select.form-control(:id="section_name" :class="{'placeholder': selected[section_name] === null}"
                                            :disabled="isDisabledOption(section) || isShine(selected[section_name])"
                                            v-model="selected[section_name]" @change="updateOption(section_name)")
                                            option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                                            option(v-for="radio in section" :class="{'hide': isShine(radio.id)}" :disabled="isShine(radio.id)"
                                                :value="radio.id" :selected="radio.id === selected[section_name]") {{radio.name_long}}

                                    .col-md-3.text-center(v-if="selected[section_name] && (selected_option[section_name] && selected_option[section_name].brutto_price > 0) || (selected_option[section_name].name_long && selected_option[section_name].name_long.includes('-SX'))")
                                        small.text-muted.page-header {{selected_option[section_name].brutto_price|currency}}

                        .form-horizontal(v-if="selected.coloration >= 12 && selected.coloration <= 30 && options[2] &&  options[2]['gradient_type'].length")
                            .form-group
                                label.col-md-3.control-label(for="gradient_type") {{'catalog.lenses.properties.heading.gradient_type'|translate}}
                                .col-md-6
                                    select#gradient_type.form-control(:class="{'placeholder': selected['gradient_type'] === null}"
                                        v-model="selected.gradient_type" @change="updateOption('gradient_type')")
                                        option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                                        option(v-for="type in  options[2]['gradient_type']" :value="type.id" :placeholder="'Wybierz'") {{type.name_long}}

                    div
                        .col-xs-offset-9.col-xs-3.border-top-separate(style="border-top-width:1px" v-if="type_id")
                            span.pull-left {{'tasks.lenses.SUM'|translate}}:
                            span.pull-right.text-bold {{total_price|currency}}

                        .col-xs-12.border-top-separate(style="border-top-width:1px" v-if="type_id")
                            .alert.alert-danger.alert-padding(v-show="alerts.length")
                                div
                                    i.fa.fa-exclamation-circle.mr3
                                    span {{'tasks.lenses.notify.warnings.HEADING'|translate}}

                                ul
                                    li(v-for="alert in alerts" v-html="alert")

                            .alert.alert-info.alert-padding(v-if="offerHoyaOption && offerHoyaOption.id !== (selected_option.coloration ? selected_option.coloration.id : 0)")
                                div
                                    i.fa.fa-question-circle.mr3
                                    span {{'tasks.offer.lenses.warning.HEADER'|translate}}

                                ul
                                    li
                                        span.mr3 {{'tasks.offer.lenses.warning.COLORATION'|translate}}
                                        i.mr3 {{'catalog.lenses.properties.heading.' + offerHoyaOption.section|translate}}
                                        b {{offerHoyaOption.name}}
                                        span .

                            OptiButtonSave.pull-right(style="margin-top:15px"
                                :disabled="!!alerts.length || !!ranges.errors.length || loading.range" @click.native="save")

                        .col-xs-12.border-top-separate(style="margin-top:15px;border-top-width:1px")

                            .form-horizontal(style="margin-top:15px")
                                .form-group.text-center(v-if="type_id && Object.keys(types[type_id].substitutes).length")
                                    label.col-md-3.control-label(for="refer_to") {{'catalog.lenses.properties.navi.SWITCH'|translate}}
                                    .col-md-6
                                        select#refer_to.form-control(style="margin-bottom:15px" :class="{'placeholder': refer_to === null}"
                                            v-model="refer_to" @change="goSwitch")
                                            option.hide(:value="null" disabled) {{'catalog.lenses.CHECK'|translate}}
                                            optgroup(v-for="(substitutes, substitute_name) in types[type_id].substitutes" :key="substitute_name" :label="substitute_name")
                                                option(v-for="(catalog_id, sub_name) in substitutes" :value="catalog_id") {{sub_name}}

        .col-xs-12.text-center(v-if="page_catalog && type_id && types[type_id].image_url && !loading.main")
            OptiCatalogPageSwitcher(
                :design="design.data[design_type_id][clear_polar_photo_id]"
                :current_index="index_id"
                :image_url="'upload/' + types[type_id].image_url"
                :group="lenses[catalog.id][catalog.sub_id]"
                :design_id="design.id"

                @index_change="updateFromPage"
                @change_design="updateDesignFromPage")

        .col-xs-12
            OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'
    import {TaskHoyaHelper} from '@/js/app/vue/helpers/TaskHoyaHelper'
    import {EnumCategoryLensDeliveryType} from '@/js/app/vue/enums/Enums'

    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiTaskDetailsLensesRanges
        from '@/js/app/vue/components/Tasks/Details/Lenses/OptiTaskDetailsLensesRanges'
    import OptiPictograms from '@/js/app/vue/components/Pictograms/OptiPictograms'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiCatalogPageSwitcher from '@/js/app/vue/components/Tasks/Details/Lenses/Pages/OptiCatalogPageSwitcher'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetailsLensesPageCatalog',
        components: {
            OptiButton,
            OptiTaskDetailsLensesRanges,
            OptiPictograms,
            OptiButtonSave,
            OptiCatalogPageSwitcher,
            OptiDimmer
        },
        props: {
            task: {
                type: Object,
                required: true
            },
            isOffer: {
                type: Boolean,
                required: true
            },
            isCompare: {
                type: Boolean,
                required: true
            },
            offerHoyaOption: {
                required: true
            }
        },
        data() {
            return {
                firstChoice: {},

                catalogs: {},
                catalog: {
                    id: null,
                    sub_id: null
                },

                lenses: {},

                design: null,
                design_type_id: null,
                clear_polar_photo_id: null,
                index_id: null,
                type_id: null,

                types: null,
                options: null,

                selected: {},
                selected_option: {},
                coloration: {
                    required: false,
                    without_cf_and_cg: false,
                    only_contrast_and_cf: false,
                    only_cf_and_cg: false
                },
                mode_undefined: false,

                ranges: {
                    errors: [],
                    warnings: []
                },

                field: {
                    hoya_lens_id: this.isCompare ? 'hoya_lens_id_2' : 'hoya_lens_id',
                    hoya_lens_category_id: this.isCompare ? 'hoya_lens_category_id_2' : 'hoya_lens_category_id',
                    hoyaOptions: this.isCompare ? 'hoyaOptions2' : 'hoyaOptions',
                    progression_length: 'progression_length',
                    degression_length: 'szajna_degression_length'
                },

                refer_to: null,
                page_catalog: false,
                loading: {
                    main: true,
                    range: false
                },
                delivery_type_name: ''
            }
        },
        mounted() {
            API.get('task/hoya/grouped/' + this.task.type).then(res => {
                this.lenses = this.getFilteredData(res.data)

                this.goSelectLenses()
            }).finally(() => {
                this.loading.main = false
            }).catch(() => {
                this.$notify.error('tasks.pack.catalog.notify.error.LOAD')
            })
        },
        methods: {
            getFilteredData(data) { //TODO ogólnie do usunięcia jak skończą się prace na Szajną
                if(!this.$uac.user.activeRole.admin) {
                    if('Szajna' in data) {
                        delete data.Szajna
                    }

                    if (data.SunLoox && 'SunLoox 2.0' in data.SunLoox) {
                        delete data.SunLoox['SunLoox 2.0']
                    }
                }

                return data
            },
            updateFromPage(idx) {
                let type = this.type_id
                this.updateIndex(idx)

                if(type in this.types) {
                    this.updateType(type)
                } else {
                    type = Object.keys(this.types)[0]
                    this.updateType(type)
                }
            },
            updateDesignFromPage(design) {
                this.design = design

                let design_type_id = Object.keys(this.design.data)
                let clear_polar_photo = Object.keys(this.design.data[design_type_id[0]])

                let index = Object.keys(this.design.data[design_type_id[0]][clear_polar_photo[0]])

                this.design_type_id = design_type_id[0];
                this.clear_polar_photo_id = clear_polar_photo[0]

                this.updateIndex(index[0])

                this.type_id = Object.keys(this.types)[0]

                this.getRanges()
            },
            resetChanges() {
                this.catalog = this.firstChoice.catalog
                this.lenses = this.firstChoice.lenses
                this.design = this.firstChoice.design
                this.design_type_id = this.firstChoice.design_type_id
                this.clear_polar_photo_id = this.firstChoice.clear_polar_photo_id
                this.index_id = this.firstChoice.index_id
                this.type_id = this.firstChoice.type_id
                this.types = this.firstChoice.types
                this.options = this.firstChoice.options
                this.selected_option = this.firstChoice.selected_option
                this.selected = this.firstChoice.selected
                this.page_catalog = false

                this.getRanges()
            },
            goToPageCatalog() {
                this.firstChoice = {
                    catalog: this.catalog,
                    lenses: this.lenses,
                    design: this.design,
                    design_type_id: this.design_type_id,
                    clear_polar_photo_id: this.clear_polar_photo_id,
                    index_id: this.index_id,
                    type_id: this.type_id,
                    types: this.types,
                    options: this.options,
                    selected_option: this.selected_option,
                    selected: this.selected,
                }

                this.page_catalog = true
            },
            isShine(x) {
                return x === 96 || x === 97 || x === 98
            },
            isNotEmptyOption(option) {
                let _return = false

                _.forEach(option, (section, section_name) => {
                    if(section_name !== this.field.progression_length && section_name !== this.field.degression_length && !_return) {
                        _return = this.isNotEmptySection(section)
                    }
                })

                return _return
            },
            isNotEmptySection(section) {
                let _return = false

                if(Object.keys(section).length && isNaN(Object.keys(section)[0])) {
                    _.forEach(section, s => {
                        if(!_return) {
                            _return = !!s.length
                        }
                    })
                } else {
                    _return = !!section.length
                }

                return _return
            },
            isDisabledOption(section) {
                let _return = 0

                if(Object.keys(section).length && isNaN(Object.keys(section)[0])) {
                    _.forEach(section, s => {
                        _return += s.length
                    })
                } else {
                    _return += section.length
                }

                return _return === 1
            },

            updateCatalogSub() {
                _.forEach(this.lenses, (lens, lens_type) => {
                    _.forEach(lens, (l, l_sub_id) => {
                        if(l_sub_id === this.catalog.sub_id) {
                            this.catalog.id = lens_type
                        }
                    })
                })

                this.design = null
                this.updateDesign()
            },
            updateDesign() {
                let design_type_ids = this.design ? Object.keys(this.design.data) : []

                this.updateDesignType(design_type_ids.length === 1 ? design_type_ids[0] :
                    (design_type_ids.indexOf('RX') > -1 ? design_type_ids[design_type_ids.indexOf('RX')] : null))
                this.updateDeliveryType(this.design ? this.design.type_delivery_id : "")
            },
            updateDesignType(design_type_id) {
                this.design_type_id = design_type_id

                if(this.design_type_id) {
                    this.design.data[this.design_type_id] = TaskHoyaHelper.sort(this.design.data[this.design_type_id])

                    let clear_polar_photo_ids = Object.keys(this.design.data[this.design_type_id])

                    this.updatePolSen(clear_polar_photo_ids.length === 1 ? clear_polar_photo_ids[0] : null)
                } else {
                    this.updatePolSen(null)
                }
            },
            updatePolSen(clear_polar_photo_id) {
                this.clear_polar_photo_id = clear_polar_photo_id

                if(this.clear_polar_photo_id) {
                    this.design.data[this.design_type_id][this.clear_polar_photo_id] = TaskHoyaHelper.sort(this.design.data[this.design_type_id][this.clear_polar_photo_id])

                    let index_ids = Object.keys(this.design.data[this.design_type_id][this.clear_polar_photo_id])

                    this.updateIndex(index_ids.length === 1 ? index_ids[0] : null)
                } else {
                    this.updateIndex(null)
                }
            },
            updateIndex(index_id, update_offer_color) {
                this.index_id = index_id

                if(this.index_id) {
                    let {types, options} = this.design.data[this.design_type_id][this.clear_polar_photo_id][this.index_id]

                    this.types = TaskHoyaHelper.sort(types)
                    this.options = options

                    let type_ids = Object.keys(this.types)

                    this.mode_undefined = (type_ids.length > 1 && type_ids[0] === 'undefined') ||
                        (type_ids.length === 1 && type_ids[0] === 'undefined' && this.options[0].coatings.coatings.length === 1 &&
                            this.types[type_ids[0]].name_long.includes(
                                this.options[0].coatings.coatings[0].name_long === 'Hi-Vision Meiryo' ? 'Meiryo' : this.options[0].coatings.coatings[0].name_long))

                    this.selected = {}
                    this.selected_option = {}
                    this.coloration = {
                        required: false,
                        without_cf_and_cg: false,
                        only_contrast_and_cf: false,
                        only_cf_and_cg: false
                    }

                    _.forEach(this.options, option => {
                        _.forEach(option, (sections, section_name) => {
                            if(this.isNotEmptySection(sections)) {
                                let _length = 0,
                                    _id = 0
                                _.forEach(sections, (section, section_key) => {
                                    if(isNaN(section_key)) {
                                        if(section.length) {
                                            _length += section.length
                                            _id = section[0].id
                                        }
                                    } else {
                                        ++_length
                                        _id = section.id
                                    }
                                })

                                this.updateOption(section_name, false, _length === 1 ? _id : 0)
                            }
                        })
                    })

                    this.updateType(type_ids.length === 1 ? type_ids[0] : null)
                } else {
                    this.types = null
                    this.options = null

                    this.selected = {}
                    this.selected_option = {}

                    this.mode_undefined = false

                    this.updateType(null)
                }

                if(!!update_offer_color) {
                    this.selectColorationFromOffer()
                }
            },
            updateType(type_id) {
                this.type_id = type_id

                this.refer_to = null

                this.getRanges()
            },
            updateOption(section_name, is_option, id) {
                this.$set(this.selected, section_name, id ? id :
                    typeof this.selected[section_name] !== 'undefined' ? this.selected[section_name] :
                        (section_name === 'coloration' ? 0 : null))

                if(is_option) {
                    this.$set(this.selected_option[section_name], 'id', this.selected[section_name])
                } else {
                    this.$set(this.selected_option, section_name, TaskHoyaHelper.getOption(this.options, this.selected[section_name]))

                    if(section_name === 'coatings' && !_.isEmpty(this.selected_option[section_name])) {
                        this.coloration = this.selected_option[section_name].coloration

                        let conditions = [294, 295, 296]
                        let test = conditions.some(el => this.selected_option[section_name].id.toString().includes(el))

                        if(test === true) {
                            let temp = this.selected_option[section_name].id
                            if(temp === 294) {
                                this.$set(this.selected_option['sensity'], TaskHoyaHelper.getOption(this.options, 98))
                                this.selected['sensity'] = 98
                            }
                            if(temp === 295) {
                                this.$set(this.selected_option['sensity'], TaskHoyaHelper.getOption(this.options, 96))
                                this.selected['sensity'] = 96
                            }
                            if(temp === 296) {
                                this.$set(this.selected_option['sensity'], TaskHoyaHelper.getOption(this.options, 97))
                                this.selected['sensity'] = 97
                            }
                        }

                        if(test === false && this.isShine(this.selected['sensity'])) {
                            this.selected['sensity'] = null
                        }

                        this.isShine(this.selected_option['sensity'])
                        this.checkIfAllowColoration()
                    }
                }

                if(section_name === 'coloration') {
                    if(!this.getSubColorationIds('coloration_gradient').includes(this.selected.coloration)) {
                        this.$set(this.selected, 'gradient_type', null)
                    }
                }

                this.setUndefined()
                this.resetSzajnaMirrorChromax(section_name)
            },
            updateDeliveryType(type_delivery_id) {
                if(EnumCategoryLensDeliveryType.HOYA.id === type_delivery_id) {
                  this.delivery_type_name = 'HOYA'
                  this.field.progression_length = 'progression_length'

                } else if (EnumCategoryLensDeliveryType.SZAJNA.id === type_delivery_id) {
                  this.delivery_type_name = 'SZAJNA'
                  this.field.progression_length = 'szajna_progression_length'

                } else {
                  this.delivery_type_name = ''
                  this.field.progression_length = 'progression_length'
                }
            },
            setUndefined() {
                if(this.mode_undefined || this.design.id === 21) { //MAXXEE jest uszukanym undefinedem przez 1.56
                    let _selected = [],
                        find = '',
                        exceptions = ['BlueControl']

                    this.options.forEach(option => {
                        _.forEach(option, sections => {
                            _.forEach(sections, (section, section_key) => {
                                if(isNaN(section_key)) {
                                    section.forEach(sub_section => {
                                        find = this.translateUndefined(sub_section)
                                        if(find) {
                                            _selected.push({
                                                section: sub_section,
                                                find: find
                                            })
                                        }
                                    })
                                } else {
                                    find = this.translateUndefined(section)
                                    if(find) {
                                        _selected.push({
                                            section: section,
                                            find: find
                                        })
                                    }
                                }
                            })
                        })
                    })

                    let filtered = this.types

                    _selected.forEach(property => {
                        let find = []

                        _.forEach(filtered, type => {
                            if(type.name_long.toLowerCase().includes(property.find.toLowerCase())) {
                                let is_exception = false
                                exceptions.forEach(exception => {
                                    if(type.name_long.includes(exception) && !property.find.includes(exception)) {
                                        is_exception = true
                                    }
                                })

                                if(!is_exception) {
                                    property.section.brutto_price = 0
                                    property.section.name_included = true

                                    find.push(type)
                                }
                            }
                        })

                        if(find.length) {
                            filtered = find
                        }
                    })

                    if(filtered.length === 1) {
                        _.forEach(this.types, (type, type_name) => {
                            if(type.id === filtered[0].id) {
                                this.updateType(type_name)
                            }
                        })
                    } else {
                        this.updateType(null)
                    }
                }
            },
            translateUndefined(section) {
                let _return = '',
                    translate = { // to samo w HoyaOrder.php:$translate
                        Brazowy: ['Braz', 'Brown'],
                        Szary: ['Szary', 'Gray'],
                        Zielony: ['Zielony', 'Green'],
                        'HVL UV Control': ['HVL UV', 'HVL UV'],
                        'Hi-Vision Meiryo 1.50': ['Meiryo', 'Meiryo'],
                        'Hi-Vision Meiryo': ['Meiryo', 'Meiryo']
                    }

                if(Object.values(this.selected).includes(section.id)) {
                    _.forEach(translate, (replace, find) => {
                        if(section.name_long.includes(find)) {
                            _return = replace[this.task.type === 3 ? 0 : 1]
                        }
                    })

                    if(!_return) {
                        _return = section.name_long
                    }
                }

                return _return
            },
            save() {
                let selected = [this.types[this.type_id].name_long, this.design_type_id],
                    price = this.types[this.type_id].brutto_price,
                    options = {}

                _.forEach(this.selected, option => {
                    if(option) {
                        let o = TaskHoyaHelper.getOption(this.options, option)

                        price += o.brutto_price

                        let name_long = o.name_long

                        if(o.options) {
                            o.options.forEach(opt => {
                                if(opt.id === o.id) {
                                    name_long += ' ' + opt.name
                                }
                            })
                        }

                        if(!o.name_included) {
                            selected.push(name_long)
                        }

                        options[o.id] = {
                            price: o.brutto_price
                        }
                    }
                }); //TODO: Nie wiem dlaczego tutaj musi być średnik …

                ['right', 'left'].forEach(side => {
                    let field = side + 'Lens' + (this.isCompare ? '2' : '')

                    this.task[field].productId = 0
                    this.task[field].partName = selected.join('|')
                    this.task[field].manufacturer = 'Hoya'
                    this.task[field].type = this.types[this.type_id].type
                    this.task[field].price = price / 2
                })

                this.task[this.field.hoya_lens_id] = this.types[this.type_id].id
                this.task[this.field.hoya_lens_category_id] = this.types[this.type_id].category_id
                this.task[this.field.hoyaOptions] = options

                TaskHoyaHelper.setTaskExtras(this.isCompare, this.ranges, this.task)

                this.$emit('lenses-was-updated')
                this.$emit('go-to-page', 'summary')
            },
            getSubColorationIds(type) {
                return [...new Set([].concat(...this.options[1].coloration[type]
                    .map(o => o.id > 0 ? [Math.abs(o.id)] : o.options.map(o => Math.abs(o.id)))))]
            },

            goSwitch() {
                let path = this.getPath()

                if(path.type_id) {
                    this.catalog.sub_id = path.catalog_sub_id
                    this.updateCatalogSub()

                    this.design = path.design
                    this.updateDesign()
                    this.updateDesignType(path.design_type_id)

                    this.updatePolSen(path.clear_polar_photo_id)
                    this.updateIndex(path.index_id)
                    this.updateType(path.type_id)
                } else {
                    this.$notify.error('catalog.lenses.notify.error.not_found.SUBSTITUTE')
                }
            },
            getPath() {
                let _return = {
                    catalog_id: null,
                    catalog_sub_id: null,
                    design: null,
                    design_type_id: null,
                    clear_polar_photo_id: null,
                    index_id: null,
                    type_id: null
                }

                let {catalog_id, catalog_sub_id} = this.getCatalogAndSubCatalog()

                _return.catalog_id = catalog_id
                _return.catalog_sub_id = catalog_sub_id

                if(catalog_sub_id) {
                    this.lenses[catalog_id][catalog_sub_id].forEach(design => {
                        _.forEach(design.data, (design_type, design_type_id) => {
                            _.forEach(design_type, (clear_polar_photo, clear_polar_photo_id) => {
                                _.forEach(clear_polar_photo, (index, index_id) => {
                                    _.forEach(index.types, (type, type_id) => {
                                        if(type.category_id === this.refer_to &&
                                            (this.types[this.type_id].id === type.id ||
                                                this.types[this.type_id].substitute_id === type.id ||
                                                this.types[this.type_id].id === type.substitute_id)) {
                                            _return.design = design
                                            _return.design_type_id = design_type_id
                                            _return.clear_polar_photo_id = clear_polar_photo_id
                                            _return.index_id = index_id
                                            _return.type_id = type_id
                                        }
                                    })
                                })
                            })
                        })
                    })
                }

                return _return
            },
            getCatalogAndSubCatalog() {
                let _return = {
                    catalog_id: null,
                    catalog_sub_id: null
                }

                _.forEach(this.types[this.type_id].substitutes, (substitutes, substitute_name) => {
                    _.forEach(substitutes, (catalog_id, sub_name) => {
                        if(catalog_id === this.refer_to) {
                            _return.catalog_id = substitute_name
                            _return.catalog_sub_id = sub_name

                            return
                        }
                    })
                })

                return _return
            },
            goSelectLenses() {
                let _find = false

                if(this.task[this.field.hoya_lens_id]) {
                    _.forEach(this.lenses, (catalog, catalog_id) => {
                        _.forEach(catalog, (catalog_sub, catalog_sub_id) => {
                            _.forEach(catalog_sub, design => {
                                _.forEach(design.data, (design_type, design_type_id) => {
                                    _.forEach(design_type, (clear_polar_photo, clear_polar_photo_id) => {
                                        _.forEach(clear_polar_photo, (index, index_id) => {
                                            _.forEach(index.types, (type, type_id) => {
                                                if(type.id === this.task[this.field.hoya_lens_id] && type.category_id === this.task[this.field.hoya_lens_category_id]) {
                                                    _find = true

                                                    this.catalog.id = catalog_id
                                                    this.catalog.sub_id = catalog_sub_id
                                                    this.updateCatalogSub()

                                                    this.design = design
                                                    this.updateDesign()
                                                    this.updateDesignType(design_type_id)

                                                    this.updatePolSen(clear_polar_photo_id)
                                                    this.updateIndex(index_id)
                                                    this.updateType(type_id)

                                                    this.goSelectOptions()

                                                    this.updateDeliveryType(design.type_delivery_id)
                                                }
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                }

                if(!_find) {
                    this.catalog.id = Object.keys(this.lenses)[0]
                    this.catalog.sub_id = Object.keys(this.lenses[this.catalog.id])[0]

                    if(this.task[this.field.hoya_lens_id]) {
                        this.$notify.error('catalog.lenses.notify.error.not_found.CONSTRUCTION')
                    }
                }
            },
            goSelectOptions() {
                let not_found = false

                this.task[this.field.hoyaOptions].forEach(hoya_option => {
                    let find = {
                        section_name: '',
                        option: 0
                    }

                    this.options.forEach(rows => {
                        _.forEach(rows, (row, row_section_name) => {
                            _.forEach(row, (section, section_name) => {
                                if(isNaN(section_name)) {
                                    section.forEach(option => {
                                        if(option.id === hoya_option.id) {
                                            find.section_name = row_section_name
                                        } else if(option.options) {
                                            option.options.forEach(sub_option => {
                                                if(sub_option.id === hoya_option.id) {
                                                    find.section_name = row_section_name
                                                    find.option = option.id
                                                }
                                            })
                                        }
                                    })
                                } else {
                                    if(section.id === hoya_option.id) {
                                        find.section_name = row_section_name
                                    }
                                }
                            })
                        })
                    })

                    if(find.section_name) {
                        if(find.option) {
                            this.updateOption(find.section_name, false, find.option)
                        }

                        this.updateOption(find.section_name, find.option, hoya_option.id)
                    } else {
                        not_found = true
                    }
                })

                if(not_found) {
                    this.$notify.error('catalog.lenses.notify.error.not_found.OPTIONS')
                }
            },

            checkIfAllowColoration() {
                if(this.selected.coloration) {
                    let sub_section_name = '',
                        selected_sub_option = null

                    _.forEach(this.options[1].coloration, (options, option_section_name) => {
                        options.forEach(option => {
                            if(option.id === this.selected.coloration) {
                                sub_section_name = option_section_name

                                if(option.options) {
                                    option.options.forEach(sub_option => {
                                        if(sub_option.id === option.id) {
                                            selected_sub_option = sub_option
                                        }
                                    })
                                }
                            }
                        })
                    })

                    if(this.disabledColor(sub_section_name, this.selected_option.coloration) ||
                        (selected_sub_option && this.disabledSaturation(selected_sub_option))) {
                        this.selected.coloration = null

                        _.forEach(this.options[1].coloration, coloration_options => {
                            coloration_options.forEach(option => {
                                if(option.options) {
                                    option.id = option.options[0].id
                                }
                            })
                        })

                        this.updateOption('coloration')

                        this.$notify.warning('catalog.lenses.notify.DELETE_COLORATION')
                    }
                }
            },

            selectColorationFromOffer() {
                if(this.offerHoyaOption) {
                    if(this.selected_option.coloration && !this.selected_option.coloration.id) {
                        let {coloration_id_minus, coloration_id} = TaskHoyaHelper.findColor(this.options[1].coloration, this.offerHoyaOption.id)

                        if(this.disabledColor(this.offerHoyaOption.section, TaskHoyaHelper.getOption(this.options, coloration_id_minus))) {
                            this.$notify.warning('tasks.offer.lenses.notify.warning.NO_AVAILABLE_COLORATION', true, true)
                        } else {
                            if(coloration_id_minus) {
                                this.updateOption('coloration', false, coloration_id_minus)
                            }

                            this.updateOption('coloration', coloration_id_minus, coloration_id)

                            if(this.selected_option.coloration && !this.selected_option.coloration.id) {
                                this.$notify.warning('tasks.offer.lenses.notify.warning.NO_AVAILABLE_COLORATION', true, true)
                            }
                        }
                    }
                }
            },

            getRanges() {
                if(this.type_id) {
                    this.loading.range = true

                    API.post('task/hoya/catalog/validate', {
                        type: 'catalog',
                        task_id: this.task.id,
                        is_offer: this.isOffer,
                        is_compare: this.isCompare,
                        category_id: this.types[this.type_id].category_id,
                        hoya_lens_id: this.types[this.type_id].id
                    },  API.id(Math.random())).then(res => {
                        this.ranges = res.data
                    }).finally(() => {
                        this.loading.range = false
                    }).catch(() => {
                        this.$notify.error('tasks.lenses.notify.error.LOAD_RANGES')
                    })
                } else {
                    this.ranges = {
                        errors: [],
                        warnings: []
                    }
                }
            },

            disabledColor(sub_section_name, option) {
                return TaskHoyaHelper.disabledColor(this.coloration, sub_section_name, option)
            },
            disabledSaturation(opt) {
                return TaskHoyaHelper.disabledSaturation(this.coloration, opt)
            },
            notHideSzajnaMirrorChromax(section_name) {

                if(section_name !== 'szajna_mirror_chromax') {
                    return true
                }

                // Coating: 'Lustrzana SPECTRA'
                if(this.selected && this.selected.coatings && this.selected.coatings === 355 ) {
                  return true
                }

                return false
            },
            resetSzajnaMirrorChromax(section_name) {
              if(section_name === 'coatings' &&  this.selected && this.selected.coatings !== 355 && this.selected['szajna_mirror_chromax']) {
                this.selected['szajna_mirror_chromax'] = null;
              }
            }
        },
        computed: {
            alerts() {
                let _return = []

                if(this.type_id && !this.types[this.type_id].ilog_code) {
                    _return.push(this.$filters.translate('tasks.lenses.notify.warnings.NOILOGCODE') + ' <b>' +
                        this.types[this.type_id].name_long + '</b>.')
                }

                _.forEach(this.selected, (section_id, section_name) => {
                    if((section_id === null || section_id < 0) && section_name !== 'gradient_type' && this.notHideSzajnaMirrorChromax(section_name) ||
                        (this.selected.coloration >= 12 && this.selected.coloration <= 30 && section_name === 'gradient_type' && section_id === null)) {
                        _return.push(this.$filters.translate('tasks.lenses.notify.warnings.NOTSELECTED') + ' <b>' +
                            this.$filters.translate('catalog.lenses.properties.heading.' + section_name.toLowerCase()).toLowerCase() + '</b>.')
                    }
                })

                if(this.coloration.required && !(this.selected.coloration || this.selected.polarized || this.selected.polarized_fine || this.selected.sensity)) {
                    _return.push(this.$filters.translate('tasks.lenses.notify.warnings.COLOR_REQUIRED'))
                }

                return _return
            },

            total_price() {
                let total_price = this.types[this.type_id].brutto_price

                _.forEach(this.selected, option_id => {
                    if(option_id) {
                        let option = TaskHoyaHelper.getOption(this.options, option_id)
                        if(option.id > 0 && option.brutto_price) {
                            total_price += option.brutto_price
                        }
                    }
                })

                return total_price
            },

            pictogramsImprovements() {
                let pictograms = Object.values(this.selected_option)
                    .map(o => o.pictograms)
                    .flat()
                    .filter(p => typeof p !== 'undefined')

                if(this.types[this.type_id] && this.types[this.type_id].pictograms.sensity) {
                    pictograms.push(this.types[this.type_id].pictograms.sensity)
                }

                if(this.types[this.type_id] && this.types[this.type_id].pictograms.mets) {
                    pictograms.push(this.types[this.type_id].pictograms.mets)
                }

                return pictograms
            },
            sortedOptionsClearPolarPhoto() {
                if (!this.design_type_id || !this.design.data[this.design_type_id]) {
                    return [];
                }

                return  Object.keys(this.design.data[this.design_type_id]).sort((a, b) => a.localeCompare(b))
            }
        }
    }
</script>

<style lang="less" scoped>
    .page-header {
        margin-top: 0;

        &.pictograms {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding-bottom: 0;

            > a {
                white-space: break-spaces;
            }
        }
    }

    .alert-padding {
        margin-bottom:0;
        margin-top:15px;
    }
</style>
